<template>
    <div class="dynamic">
       <div class="banner"> 
            <div class="banner-container">
                <div>
                    <h2>关于我们</h2>
                    <h4></h4>
<!--                    <p>重庆天行健科技有限公司</p>-->
                </div>
                <img src="../assets/img/app development.png" alt="#">
            </div>
        </div>
        <div class="content">
            <div class="content-container">
                <div>
                    <h2><span></span>我们的服务</h2>
                    <p>
                        公司成立于2021年01月，经营包括网络技术服务，信息技术咨询服务，广告设计、代理，广告发布（非广播电台、电视台、报刊出版单位），广告制作（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）等互联网技术和广告相关服务；
                    </p>
                    <p>
                        平台主要服务于国内的初创互联网产品，助其推广、扩张及规模化成型。媒体服务主要依托于增值运营，深挖生态闭环资源，能够大幅有效的提升资源价值；
                    </p>
                    <p>
                        平台海外服务可以为国际化产品提供标准化Google等资源对接，帮助其投放、运营及规模化，同时非标准化资源可以根据产品或媒体提供专项服务；
                    </p>
                </div>
                <div>
                    <h2><span></span>我们的资源优势</h2>
                    <p>
                        扬歌平台拥有国内外主流Ad network、Ad Exchange、DSP平台20余家，对接数万级的广告主资源，覆盖37个行业（品牌、效果、App推广等），可以实现每一个流量充分竞价，全面提升媒体的变现能力和收益；
                    </p>
                    <p>
                        自研扬歌广告小程序服务插件完全支持腾讯官方小程序广告接入，该服务系统由腾讯云支持，流量安全性强，支持小程序流量植入、整形，流量真实性高，部分服务内容支持h5广告落地页或小程序原生页面落地页广告推广及产品接入；
                    </p>
                    <p>
                        整合了国内95%以上的一线sdk服务，实现资源互补，并为不同产品提供不同的对接方案帮助其获取稳定的广告预算资源。自有运营体系能够很好的实现流量的价值提升；
                    </p>
                </div>
                <div>
                    <h2><span></span>我们的团队分工及技术支持</h2>
                    <p>
                        平台拥有专业的技术研发、广告运营和广告投放团队，提供真正的全流程服务，增值价值普遍高于市场20%-30%；
                    </p>
                    <p>
                        平台海外服务可以为国际化产品提供标准化Google等资源对接，帮助其投放、运营及规模化，同时非标准化资源可以根据产品或媒体提供专项服务；
                    </p>
                </div>
                <div class="posi-absolute">  
                    
                </div>
                <!-- <img src="../assets/img/webg.webp" alt=""> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
    @import url('../assets/css/theme-color.less');
    .dynamic{
        .banner{
            background:url('../assets/img/type-banner-bg.png');
            background-size: contain;
            .banner-container{
                width: 1200px;
                margin: 0 auto;
                position: relative;
                display: flex;
                align-items: center;
                height: 430px;
                overflow: hidden;
                img:nth-of-type(1){
                    position: absolute;
                    top: 30px;
                    right: 0px;
                    width: 490px;
                }
                >div{
                    h2{
                        font-size: 40px;
                        font-weight: 600;
                    }
                    h4{
                        background: @themeColor;
                        width: 32px;
                        height: 4px;
                        margin: 12px 0 24px;
                    }
                    p{
                        font-size: 18px;
                        width: 530px;
                    }
                }
            }
        }
        .content{
            padding: 60px 0 150px;
            background: #fefefe;
            .content-container{
                position: relative;
                padding:2px 0 50px 50px;
                margin: 0 auto;
                width: 1200px;
                h2{
                    font-size: 24px;
                    font-weight: 600;
                    margin: 60px 0 40px;
                    position: relative;
                    span{
                        position: absolute;
                        width: 30px;
                        height: 12px;
                        background: @themeColor;
                        top: 10px;
                        left: -58px;
                        border-bottom-right-radius:6px;
                        border-top-right-radius:6px;
                    }
                }
                p{
                   margin-top: 36px;
                }
                >div{
                    margin-bottom: 80px;
                }
                >img{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    opacity: .1;
                    width: 800px;
                }
            }
            .posi-absolute{
                position: absolute;
                left: 0;
                top: 0;
                width: 2px;
                height: 100%;
                background: @themeColor;
            }
            
        }
    }
</style>